<template>
		<div class="friend-welcome-message" v-loading='loading'>
			<el-card>
				<div class="welcome-edit-box">
					<!-- :rules="rules" -->
					<el-form label-width="100px" class="edit-box-ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm">
						 <el-form-item label="问题：" prop="answer" >
                             <el-input v-model.trim="ruleForm.answer" style="width:450px;height:36px" placeholder="请输入问题，例:什么时候发货？" maxlength="50"></el-input>
                         </el-form-item>
						<el-form-item label="回复：" prop="textarea">
							<div style="color:#999999;font-size:12px;margin-top:0px">当客户的咨询信息与问题高度匹配时，将触发自动回复；若无法精准匹配时，向该客户推荐知识库里他可能想咨询的问题
							   <el-popover   
										placement="bottom"
										trigger="hover">
										<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/17839082246twice.png" style="width:200px" alt="">
										<el-button slot="reference" type="text">查看示例</el-button>
								</el-popover>
							 </div>
							<div class="edit-wraper">
									<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only" @input="checkLength"></div>
									<div class="num">{{length}}/500</div>
								<div class="link-wraper" style="margin-top:0px">
									<div class="file-list" v-if="fileList.length">
										<div class="file-item" v-for="(item, index) in fileList" :key="index">
											<div class="type">
												<span v-if="item.Type == 1">【图片】</span>
												<span v-if="item.Type == 2">【链接】</span>
												<span v-if="item.Type == 3">【小程序】</span>
											</div>
											<div class="txt">：{{item.LinkName}}</div>
											<div class="control">
												<div class="edit" v-if="item.Type != 1" @click="handleEditFile(item, index)">
													<i class="el-icon-edit"></i>
												</div>
												<el-upload :action="imgApi" v-else :before-upload="beforeUpload"
													:on-success="(res, file)=>{handleUploadSuccess(res, file, index)}"
													accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
													<div class="edit">
														<i class="el-icon-edit"></i>
													</div>
												</el-upload>
												<div class="close" @click="handleDeleteFile(index)">
													<i class="el-icon-close"></i>
												</div>
											</div>
										</div>
									</div>
									<el-popover placement="top" v-model="showPop" width="200" trigger="click" v-if="fileList.length < 4">
										<div class="control-wraper">
											<el-upload :action="imgApi" :before-upload="beforeUpload"
												:on-success="(res, file)=>{handleUploadSuccess(res, file, -1)}"
												accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
												<div class="item">
													<img src="@/assets/img/icon-tupian.png">
													<div>图片</div>
												</div>
											</el-upload>
											<div class="item" @click="handleAddLink">
												<img src="@/assets/img/icon-lianjie.png">
												<div>链接</div>
											</div>
											<div class="item" @click="handleAddMiniProgram">
												<img src="@/assets/img/icon-xiaochengxu.png">
												<div>小程序</div>
											</div>
										</div>
										<el-link type="primary" slot="reference">+添加图片/链接/小程序
										</el-link>
									</el-popover>
								</div>
							</div>
						</el-form-item>		
						<div class="tips-box" style="margin-left: 100px;max-width: 690px;">
							<div class="tit">提示：</div>
							<div class="cont">
								<div>1.回复内容最多可发送1条文字消息和4个附件</div>
								<div>2.文本内容不可为空；若添加多个附件（图片/链接/小程序），客户将收到多条消息</div>
							</div>
						</div>	
						<el-form-item label="分组：" style="margin-top:20px">
							<el-select v-model="ruleForm.valueOption" placeholder="请选择" @change="changeGroup" clearable style="width:450px">
											<el-option
										v-for="item in grouplist"
										:key="item.Id"
										:label="item.SmartReplyGroupName"
										:value="item.Id">
											</el-option>
                             </el-select>
						</el-form-item>
					</el-form>
				</div>
				<div>
                    <div class="footer">
				<el-button @click="handleCancel">取消</el-button>
				<el-button type="primary" :loading="saveLoading" @click="handleSave">保存</el-button>
			</div>	
			</div>	
			</el-card>
			<!-- 链接 -->
			<link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop>		
			<!-- 小程序 -->
			<miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card>

		</div>
</template>

<script>
	import {
		smartReplyGroupList,
		saveSmartReplyGroupQA,
		smartReplyGroupQAdetail
	} from '@/api/sv1.0.0.js';
	import selectEmployee from '@/components/selectEmployee/selectEmployee';	
	import linkPop from '@/views/customerServe/components/miniprogramLink';
	import miniprogramCard from '@/views/customerServe/components/miniprogramCard';
	import config from '@/config/index';	
	export default {
		components: {
			selectEmployee,
			linkPop,
			miniprogramCard
		},
		data() {
			return {
				imgApi: config.UPLOAD_IMG,
                grouplist:[],
				loading:false,
				ruleForm: {
					answer:"",
					textarea:'',
					valueOption:'',
				},
				rules: {
					textarea:[{required: true, message: '回复的文本内容不可为空', trigger: 'blur'}],
					answer: [{ required: true, message: '请输入问题', trigger: 'blur' }],
				},
				employeeVisible: false,
				length: 0,
				fileList: [],
				showPop: false,	
				miniVisible: false,
				saveLoading: false,
				cardVisible: false,
				ready: false,	
				editData: {},
				editId:'',
				startDetail:[],
			}
		},
		created(){
			  this.editId=this.$route.query.editId   
			  this.getSmartReplyGroupList()
			  if(this.editId){
				 this.smartReplyGroupQAdetail() 
			  } 
		},
		mounted() {
			this.id = this.$route.query.id || '';
			if (this.id){
				this.getData();
			}else{
				this.ready = true;
				this.initEditor();
			}		
		},
		methods: {
			checkLength() {
				this.ruleForm.textarea = document.querySelector('.edit-box').innerHTML
				this.length = document.querySelector('.edit-box').innerHTML.length;
		        },
             editBlur() {
				var html = document.querySelector('.edit-box').innerHTML;
				if(!html.length){
					this.$refs.ruleForm.validate()
					return 
				}else if (html.length > 500) {
					html = html.substr(0, 500);
				}
				this.length = html.length
				this.ruleForm.textarea = html
				this.$refs.editBox.innerHTML = html
		    },
			handleCancel(){
				 this.$router.push({path:'/customerServe/WxCustomerServe/smartReply/smartreply'})
			},
          //智能回复详情smartReplyGroupQAdetail
		  async smartReplyGroupQAdetail(){
				this.loading = true
				this.saveLoading = true
                  try{		 
					let data = {
						Id:this.editId
					}
						let res = await smartReplyGroupQAdetail(data)
          if(res.IsSuccess){
						this.startDetail=res.Result
						this.ruleForm.answer=res.Result.Question
						this.ruleForm.textarea=res.Result.ReplyText
						this.$nextTick(()=>{
							document.querySelector('.edit-box').innerHTML = this.ruleForm.textarea
						})
						this.ruleForm.valueOption=res.Result.SmartReplyGroupId
						if(!res.Result.SmartReplyGroupId){
								this.ruleForm.valueOption=''
						}else{
								this.ruleForm.valueOption=res.Result.SmartReplyGroupId
						}
				       this.fileList=res.Result.AttachmentInfoList 
				}}finally{
					this.loading = false
					this.saveLoading = false
				}
      },
			changeGroup(val){
				this.groupId=val
			},
			//获取分组名称列表
			async getSmartReplyGroupList(){
				try{
					let data = {
						KeyWord:this.ruleForm.valueOption
					}
					let res = await smartReplyGroupList(data)
					if(res.IsSuccess){
						this.grouplist=res.Result
					}}finally{}
			},
		   //配置保存
		   handleSave() {
			   this.$refs['ruleForm'].validate((valid) => {
				      if(!valid){
							this.$message.error('请完善配置项')
				   }else{
					   this.editSaveSmartReplyGroupQA()
				    } 
			   })
			},
			async editSaveSmartReplyGroupQA(){
				this.loading = true
				this.saveLoading = true
          try{		 
						//新增ID：0；保存：传ID
						let data={
							Id:this.editId,//列表ID
							SmartReplyGroupId:this.ruleForm.valueOption,
							Question:this.ruleForm.answer,
							ReplyText:this.ruleForm.textarea,
							AttachmentInfoList:this.fileList
						}
						let result = await saveSmartReplyGroupQA(data)
						if(result.IsSuccess){
							this.$message.success(({message:"保存成功"}))
							this.$router.push({path:'/customerServe/WxCustomerServe/smartReply/smartreply'})
						}
					}finally{
						this.loading = false
						this.saveLoading = false
					}
			},
			initEditor(){
			},
			handleEditFile(record, index){
				this.editIndex = index;
				if (record.Type == 2){
					this.miniVisible = true;
					this.editData = record;
				}else if (record.Type == 3){
					this.cardVisible = true;
					this.editData = record;
				}
			},
			handleAddLink(){
				this.editIndex = -1;
				this.miniVisible = true;
				this.editData = {};
			},
			handleAddMiniProgram(){
				this.editIndex = -1;
				this.cardVisible = true;
				this.editData = {};
			},
			

			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('图片大小请控制在2M以内')
					return false
				}
				return true;
			},
			handleUploadSuccess(res, file, index) {
				var obj = {
					Type: 1, //1 图片类型
					LinkName: file.name,
					ImgUrl: res[0]
				}
				
				if (index == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(index, 1, obj)
				}
				
				this.showPop = false;
			},
			handleDeleteFile(index) {
				this.fileList.splice(index, 1)
			},
			miniChange(record) {	
				var obj = {
					Type: 2, //2 链接
					LinkName: record.name,
					ImgUrl: record.imgUrl,
					LinkUrl: record.url,
					Description: record.desc
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				
			},
			cardChange(record) {
				
				var obj = {
					Type: 3, //3 小程序
					LinkName: record.name,
					LinkUrl: record.url,
					Description: record.desc,
					ImgUrl: record.imgUrl,
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				console.log(this.fileList,123)
				
			},
		}
	}
</script>

<style lang="less" scoped>
	.edit-box {
			height: 230px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}
	.friend-welcome-message {

		padding-bottom: 100px;
		.trouble{
            width: 450px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #DCDFE6;
        }
		.welcome-edit-box{
			display: flex;
			max-width: 1300px;
			width: 900px;
            margin-bottom: 20px;
			.edit-box-ruleForm{
				flex: 1 1 auto;
				margin-right: 50px;
				overflow: hidden;
			}
			
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}
		}

		.tips-box {
			padding: 10px 15px;
			border: 1px solid #409EFF;
            background-color: white;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background: white;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					// background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}
			}
			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;
			

			}
		}
		.edit-wraper {
			border: 1px solid #ddd;
			background: white;
			line-height: 18px;
			// border-radius: 4px;	
			position: relative;	
			.title {
				padding: 15px 15px;
				display: flex;
				// border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 204px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
               
                margin-top: 50px;
				border-top: 1px solid #ddd;
                background: white;


			}

			.file-list {
				overflow: hidden;
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;
						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height: 230px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
            background: white;
		}

	}
	.footer {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 220px;
		text-align: center;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		border-top: 1px solid #ddd;
	}

	@media screen and (max-width: 1280px) {
		.footer {
			left: 140px;
		}
	}
</style>
<style lang="less" scoped>
	.control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>
<style scoped>
.edit-wraper ::v-deep .el-textarea__inner:focus
               {
			   outline:none;
			   border: 0;
			   }			   
</style>

